import React from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Call from "../components/Call";

const espaBanner = () => {
  let canOpen = false;
  canOpen = confirm("This link will open in a new tab.");
  if (canOpen) {
    window.open(
      "https://www.efepae.gr/files4users/files/%CE%A0%CE%A4%CE%A5%CE%A7%CE%99%CE%9F%CE%A5%CE%A7%CE%9F%CE%99%20%CE%92%20%CE%9A%CE%A5%CE%9A%CE%9B%CE%9F%CE%A3/%CE%B1%CF%86%CE%AF%CF%83%CE%B1GR.pdf",
      "_blank"
    );
  }
};

const Home = props => {
  const intro = props.data.intro;
  const site = props.data.site.siteMetadata;
  // const services = props.data.services.edges;
  // const features = props.data.features.edges;
  const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && "intro-image-absolute"} ${
    intro.frontmatter.intro_image_hide_on_mobile && "intro-image-hide-mobile"
  }`;

  return (
    <Layout bodyClass="page-home">
      <SEO title={site.title} />
      <Helmet>
        <meta name="Data and Decisions" content="Data and Decisions" />
      </Helmet>

      <div className="row justify-content-center pt-2 espa-banner">
        <button type="button" className="no-border" onClick={() => espaBanner()}>
          <img alt="espa-banner" src={intro.frontmatter.espa_image} />
        </button>
      </div>

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
              <Call />
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img alt={intro.frontmatter.intro_image_alt} className={introImageClasses} src={intro.frontmatter.intro_image} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {services.length > 0 && ( */}
      {/*   <div className="strip"> */}
      {/*     <div className="container pt-6 pb-6 pb-md-10"> */}
      {/*       <div className="row justify-content-start"> */}
      {/*         {services.map(({ node }) => ( */}
      {/*           <div key={node.id} className="col-12 col-md-4 mb-1"> */}
      {/*             <div className="service service-summary"> */}
      {/*               <div className="service-content"> */}
      {/*                 <h2 className="service-title"> */}
      {/*                   <Link to={node.fields.slug}>{node.frontmatter.title}</Link> */}
      {/*                 </h2> */}
      {/*                 <p>{node.excerpt}</p> */}
      {/*               </div> */}
      {/*             </div> */}
      {/*           </div> */}
      {/*         ))} */}
      {/*       </div> */}
      {/*       <div className="row justify-content-center"> */}
      {/*         <div className="col-auto"> */}
      {/*           <Link className="button button-primary" to="/services/"> */}
      {/*             View All Services */}
      {/*           </Link> */}
      {/*         </div> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* )} */}

      {/* {features.length > 0 && ( */}
      {/*   <div className="strip strip-grey"> */}
      {/*     <div className="container pt-6 pb-6 pt-md-10 pb-md-10"> */}
      {/*       <div className="row justify-content-center"> */}
      {/*         {features.map(({ node }) => ( */}
      {/*           <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2"> */}
      {/*             <div className="feature"> */}
      {/*               {node.image && ( */}
      {/*                 <div className="feature-image"> */}
      {/*                   <img src={node.image} /> */}
      {/*                 </div> */}
      {/*               )} */}
      {/*               <h2 className="feature-title">{node.title}</h2> */}
      {/*               <div className="feature-content">{node.description}</div> */}
      {/*             </div> */}
      {/*           </div> */}
      {/*         ))} */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* )} */}
    </Layout>
  );
};

export const query = graphql`
  query {
    services: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/services/.*/" } }, sort: { fields: [frontmatter___weight], order: ASC }, limit: 6) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        image
        espa_image
        intro_image
        intro_image_alt
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
    features: allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
